import { useState, useCallback } from "react";
import useAuth from "./useAuth";
import axios from "axios";
import { MAIN_URL } from "../config";
import { Signal as SignalType } from "../types/Signal";

const useAlert = () => {
  const [data, setData] = useState<SignalType[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const createAlert = async (alertData: Partial<SignalType>) => {
    setLoading(true);
    setError(null);
    console.log(alertData.keywords);
    console.log(alertData.filters);
    try {
      const url = `${MAIN_URL}follow/signal`;
      //const url = "http://localhost:1040/signal";
      const response = await axios.post(url, {
        method: "POST",
        keywords: alertData.keywords,
        entity_id: alertData.entityId,
        entity_type: alertData.entityType,
        name: alertData.name,
        user_id: user.user_id,
        filters: alertData.filters,
        theme: alertData.theme,
        newsletter_creation: false,
        weekly_alerting: alertData.weekly_alerting,
        daily_alerting: alertData.daily_alerting,
      });

      setData(response.data);
      setLoading(false);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      setLoading(false);
    }
  };

  const getAlertList = useCallback(async (): Promise<
    SignalType[] | undefined
  > => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${MAIN_URL}follow/signal/list`, {
        //const response = await axios.get(`http://localhost:1040/signal/list`, {
        params: {
          user_id: user.user_id,
        },
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAlert = useCallback(
    async (signalId?: string): Promise<SignalType | undefined> => {
      setLoading(true);
      setError(null);

      try {
        //const response = await axios.get(`${MAIN_URL}/follow/keywordsummary`, {
        const response = await axios.get(
          // `http://localhost:1040/signal/summary`,
          `${MAIN_URL}follow/signal/summary`,
          {
            params: {
              user_id: user.user_id,
              signal_id: signalId,
            },
          }
        );
        setLoading(false);
        return response.data;
      } catch (err) {
        setLoading(false);
        if (axios.isAxiosError(err) && err.response) {
          setError(err.response.data);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
        return undefined;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateAlert = async (
    usercustomkeyword_id: string,
    alertData: Partial<SignalType>
  ) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(`${MAIN_URL}follow/modifykeyword`, {
        keywords: alertData.keywords,
        entity_id: alertData.entityId,
        entity_type: alertData.entityType,
        name: alertData.name,
        user_id: user.user_id,
        usercustomkeyword_id: usercustomkeyword_id,
        weekly_alerting: alertData.weekly_alerting,
        daily_alerting: alertData.daily_alerting,
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      return undefined;
    }
  };

  const deleteAlert = async (signal_id: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.delete(`${MAIN_URL}follow/signal`, {
        params: {
          user_id: user.user_id,
          signal_id: signal_id,
        },
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      return undefined;
    }
  };

  return {
    createAlert,
    getAlertList,
    getAlert,
    updateAlert,
    deleteAlert,
    loading,
    error,
  };
};

export default useAlert;
