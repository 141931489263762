import * as amplitude from "@amplitude/analytics-browser";

const AMPLITUDE_API_KEY = "3b77a046704a00c80ce9a57bbee8405d";

export const initAmplitude = () => {
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: true, // Automatically tracks page views, clicks, etc.
    //serverZone: "EU",
  });
};

export const logEvent = (
  eventName: string,
  eventProperties?: Record<string, any>
) => {
  amplitude.track(eventName, eventProperties);
};

export const setUserId = (userId: string) => {
  amplitude.setUserId(userId);
};

export const setUserProperties = (properties: amplitude.Identify) => {
  amplitude.identify(properties);
};
