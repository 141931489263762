import React, { useState } from "react";
import styled from "styled-components";
import collapseIcon from "../../assets/img/icons/collapseIcon.png";
import Article from "./Article";
import Category from "../text/Category";
import Score from "../alerts/Score";

interface Paragraph {
  text: string;
  number: number;
  wordsSaved: string[];
  paragraphId: string;
  table?: string;
  text_align?: string;
}

interface CollapserProps {
  title: string;
  subTitle?: string;
  paragraph: Paragraph[];
  statement?: string;
  score?: string[];
  signal_name?: string;
  renderParagraph?: (paragraph: Paragraph) => React.ReactNode;
}

const CollapserHero = styled.div`
  display: flex;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const CollapserTitleContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

const CollapserTitle = styled.span`
  color: #000;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

const CollapserIcon = styled.img<{ isCollapsed: boolean }>`
  height: 5px;
  transition: transform 0.2s ease;
  transform: rotate(${(props) => (props.isCollapsed ? "-90deg" : "0deg")});
`;

const ContentCollapsed = styled.div<{ isCollapsed: boolean }>`
  max-height: ${(props) => (props.isCollapsed ? "0px" : "auto")};
  opacity: ${(props) => (props.isCollapsed ? 0 : 1)};
  overflow: hidden;
  transition: max-height 0.1s ease, opacity 0.1s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow: visible;
`;

const Table = styled.div`
  width: 100%;
  height: 100%;
`;

const Statement = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: 20px;
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    width: 5px; /* Width of the left bar */
    background-color: #ff734f;
  }
`;

const StatementTitle = styled.h4`
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
`;

const Collapser: React.FC<CollapserProps> = ({
  title,
  subTitle,
  paragraph,
  renderParagraph,
  statement,
  score,
  signal_name,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Not doing a fancy handler for scoring : need to be improved
  if (subTitle) {
    title = `${title} - ${subTitle}`;
  }
  if (!score) {
    score = ["low"];
  }
  // if there is high in score then score[0] is high
  if (score.includes("high")) {
    score = ["high"];
  }

  return (
    <CollapserHero>
      <CollapserTitleContainer onClick={toggleCollapse}>
        <CollapserTitle>{title}</CollapserTitle>

        <CollapserIcon src={collapseIcon} isCollapsed={isCollapsed} />
        {score && <Score>{score[0]}</Score>}
      </CollapserTitleContainer>
      <ContentCollapsed isCollapsed={isCollapsed}>
        {paragraph.map((p, index) => (
          <Article
            key={index}
            rank={index + 1}
            wordsSaved={p.wordsSaved}
            paragraphId={p.paragraphId}
            textAlign={p.text_align}
            table={p.table}
          >
            {p.text}
            {/* Read html value from p.table */}
            {/* {p.table && <Table dangerouslySetInnerHTML={{ __html: p.table }} />} */}
          </Article>
        ))}
        {statement && (
          <div>
            <StatementTitle>Exposé des motifs</StatementTitle>
            <Statement dangerouslySetInnerHTML={{ __html: statement }} />
          </div>
        )}
      </ContentCollapsed>
    </CollapserHero>
  );
};

export default Collapser;
